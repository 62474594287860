import React from 'react'
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

function useParamQuery() {
  return new URLSearchParams(useLocation().search);
}

const PrequoteLayout = ({children}) => {
  const query = useParamQuery();
  const sorce_app = query.get('source');
  return (
    <div>
      {(sorce_app !== "referred" && sorce_app !== "app") && <Header />}
      {children}
      {(sorce_app !== "referred" && sorce_app !== "app") && <Footer />}
    </div>
  )
}

export default PrequoteLayout