import React, { lazy } from 'react';
import DefaultLayout from "../common-layouts/DefaultLayout";
import QuoteLayout from "../common-layouts/QuoteLayout";
import PrequoteLayout from "../common-layouts/PrequoteLayout";

// Lazy load components
const Home = lazy(() => import("../components/home/Home"));
const About = lazy(() => import("../components/about/About"));
const MainPolicies = lazy(() => import("../components/policies/MainPolicies"));
const TeamDetails = lazy(() => import("../components/about/TeamDetails"));
const Claim = lazy(() => import("../components/claim/Claim"));
const InsuranceAudit = lazy(() => import("../components/claim/InsuranceAudit"));
const BecomeAPosp = lazy(() => import("../components/agentposp/BecomeAPosp"));
const Thankyou = lazy(() => import("../components/agentposp/Thankyou"));
const TravelInsurance = lazy(() => import("../components/insuranceproducts/TravelInsurance"));
const PropertyInsurance = lazy(() => import("../components/insuranceproducts/PropertyInsurance"));
const ContactUs = lazy(() => import("../components/otherpage/ContactUs"));
const AnnualReturn = lazy(() => import("../components/otherpage/AnnualReturn"));
const CorporateInsurance = lazy(() => import("../components/otherpage/CorporateInsurance"));
const Reviews = lazy(() => import("../components/insuranceproducts/Reviews"));
const Blog = lazy(() => import("../components/blog/Blog"));
const BlogList = lazy(() => import("../components/blog/BlogList"));
const BlogDetails = lazy(() => import("../components/blog/BlogDetails"));
const HomeInsurance = lazy(() => import("../components/homeinsurance/HomeInsurance"));
const HealthPrequotes = lazy(() => import("../components/health/HealthPrequotes"));
const HealthQuotes = lazy(() => import("../components/health/HealthQuotes"));
const NotFound = lazy(() => import("../components/NotFound"));
const Prequotes = lazy(() => import("../components/motor/Prequotes"));
const Quotes = lazy(() => import("../components/motor/Quotes"));
const Proposal = lazy(() => import("../components/motor/Proposal"));
const Review = lazy(() => import("../components/motor/Review"));
const PaymentSuccess = lazy(() => import("../components/payments_pages/PaymentSuccess"));
const PaymentFailed = lazy(() => import("../components/payments_pages/PaymentFailed"));
const HealthProposal = lazy(() => import("../components/health/HealthProposal"));
const HealthReviews = lazy(() => import("../components/health/HealthReviews"));
const PaymentSuccessHealth = lazy(() => import("../components/payments_pages/PaymentSuccessHealth"));
const PaymentFailedHealth = lazy(() => import("../components/payments_pages/PaymentFailedHealth"));
const Advisorsearch = lazy(() => import("../components/advisor/Advisorsearch"));
const AdvisorList = lazy(() => import("../components/advisor/AdvisorList"));
const Tools = lazy(() => import("../components/otherpage/Tools"));
const Reference = lazy(() => import("../components/reference/Reference"));
const PaPrequotes = lazy(() => import("../components/personalaccident/PaPrequotes"));
const PaQuotes = lazy(() => import("../components/personalaccident/PaQuotes"));
const PaProposal = lazy(() => import("../components/personalaccident/PaProposal"));
const PaReviews = lazy(() => import("../components/personalaccident/PaReviews"));
const LifePrequotes = lazy(() => import("../components/lifeinsurance/LifePrequotes"));
const LifeQuotes = lazy(() => import("../components/lifeinsurance/LifeQuotes"));
const MarinePrequotes = lazy(() => import("../components/marineinsurance/MarinePrequotes"));
const MarineQuotes = lazy(() => import("../components/marineinsurance/MarineQuotes"));
const MarineProposal = lazy(() => import("../components/marineinsurance/MarineProposal"));
const MarineReview = lazy(() => import("../components/marineinsurance/MarineReviews"));


export const allRoute = [
  { path: "/", layout: DefaultLayout, component: Home },
  { path: "/employee", layout: DefaultLayout, component: Home },
  { path: "/company-profile", layout: DefaultLayout, component: About, exact: true },
  { path: "/team-square", layout: DefaultLayout, component: About, exact: true },
  { path: "/team-square-details", layout: DefaultLayout, component: TeamDetails, exact: true },
  { path: "/life-at-square-insurance", layout: DefaultLayout, component: About, exact: true },
  { path: "/our-gallery", layout: DefaultLayout, component: About, exact: true },
  { path: "/why-square-insurance", layout: DefaultLayout, component: About, exact: true },
  { path: "/advisory-board", layout: DefaultLayout, component: About, exact: true },
  { path: "/support-directory", layout: DefaultLayout, component: About, exact: true },
  { path: "/motor-insurance/bike-insurance/third-party-insurance", layout: PrequoteLayout, component: Prequotes, exact: true, type: 1, tp_only: 1 },
  { path: "/motor-insurance/car-insurance/third-party-insurance", layout: PrequoteLayout, component: Prequotes, exact: true, type: 2, tp_only: 1 },
  { path: "/motor-insurance/commercial-vehicle-insurance/passenger-carrying/third-party-insurance/:vtype?", layout: PrequoteLayout, component: Prequotes, exact: true, type: 3, tp_only: 1 },
  { path: "/motor-insurance/commercial-vehicle-insurance/goods-carrying/third-party-insurance/:vtype?", layout: PrequoteLayout, component: Prequotes, exact: true, type: 4, tp_only: 1 },
  { path: "/motor-insurance/commercial-vehicle-insurance/miscellaneous-insurance/third-party-insurance", layout: PrequoteLayout, component: Prequotes, exact: true, type: 5, tp_only: 1 },
  { path: "/motor-insurance/bike-insurance", layout: PrequoteLayout, component: Prequotes, exact: true, type: 1 },
  { path: "/motor-insurance/car-insurance", layout: PrequoteLayout, component: Prequotes, exact: true, type: 2 },
  { path: "/motor-insurance/commercial-vehicle-insurance/passenger-carrying/:vtype?", layout: PrequoteLayout, component: Prequotes, exact: true, type: 3 },
  { path: "/motor-insurance/commercial-vehicle-insurance/goods-carrying/:vtype?", layout: PrequoteLayout, component: Prequotes, exact: true, type: 4 },
  { path: "/motor-insurance/commercial-vehicle-insurance/miscellaneous-insurance", layout: PrequoteLayout, component: Prequotes, exact: true, type: 5 },
  { path: "/quotes/:id", layout: QuoteLayout, component: Quotes, exact: true },
  { path: "/proposal/:id", layout: QuoteLayout, component: Proposal, exact: true },
  { path: "/review/:id", layout: DefaultLayout, component: Review, exact: true },
  { path: "/payment-success/:id", layout: DefaultLayout, component: PaymentSuccess, exact: true },
  { path: "/payment-failed/:id", layout: DefaultLayout, component: PaymentFailed, exact: true },
  { path: "/privacypolicy", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/grievance-redress-policy", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/license", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/terms", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/refund-policy-and-cancellation", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/isnp", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/terms-of-use", layout: DefaultLayout, component: MainPolicies, exact: true },
  { path: "/raise-claim", layout: DefaultLayout, component: Claim, exact: true },
  { path: "/insurance-portfolio-analysis", layout: DefaultLayout, component: InsuranceAudit, exact: true },
  { path: "/become-pos", layout: DefaultLayout, component: BecomeAPosp, exact: true },
  { path: "/property-insurance", layout: DefaultLayout, component: PropertyInsurance, exact: true },
  { path: "/travel-insurance", layout: DefaultLayout, component: TravelInsurance, exact: true },
  { path: "/contactus", layout: DefaultLayout, component: ContactUs, exact: true },
  { path: "/annual-return", layout: DefaultLayout, component: AnnualReturn, exact: true },
  { path: "/corporate-insurance", layout: DefaultLayout, component: CorporateInsurance, exact: true },
  { path: "/reviews", layout: DefaultLayout, component: Reviews, exact: true },
  { path: "/blog", layout: DefaultLayout, component: Blog, exact: true },
  { path: "/blog/:slug", layout: DefaultLayout, component: BlogList, exact: true },
  { path: "/blog/:pagename/:slug", layout: DefaultLayout, component: BlogDetails, exact: true },
  { path: "/home-insurance", layout: DefaultLayout, component: HomeInsurance, exact: true },
  { path: "/health-insurance", layout: PrequoteLayout, component: HealthPrequotes, exact: true },
  { path: "/quote-health/:id", layout: QuoteLayout, component: HealthQuotes, exact: true },
  { path: "/health/proposal/:id", layout: QuoteLayout, component: HealthProposal, exact : true },
  { path: "/health/review/:id", layout: QuoteLayout, component: HealthReviews, exact : true },
  { path: "/health/payment-success/:id", layout: DefaultLayout, component: PaymentSuccessHealth, exact: true },
  { path: "/health/payment-failed/:id", layout: DefaultLayout, component: PaymentFailedHealth, exact: true },
  { path: "/employee/add_pos/:refferdId", layout: DefaultLayout, component: BecomeAPosp, exact : true },
  { path: "/find-insurance-advisor", layout: DefaultLayout, component: Advisorsearch, exact : true  },
  { path: "/insurance-advisors", layout: DefaultLayout, component: AdvisorList, exact : true  },
  { path: "/insurance-advisors/:name", layout: DefaultLayout, component: AdvisorList, exact : true  },
  { path: "/thankyou", layout: DefaultLayout, component: Thankyou, exact : true  },
  { path: "/insurance-tools/motor", layout: DefaultLayout, component: Tools, exact : true  },
  { path: "/insurance-tools/non-motor", layout: DefaultLayout, component: Tools, exact : true  },
  { path: "/insurance-tools/health", layout: DefaultLayout, component: Tools, exact : true  },
  { path: "/insurance-tools/otherdoc", layout: DefaultLayout, component: Tools, exact : true  },
  { path: "/reference", layout: QuoteLayout, component: Reference, exact : true  },
  { path: "/health-insurance/personal-accident-insurance", layout: PrequoteLayout, component: PaPrequotes, exact: true },
  { path: "/quotes-pa/:id", layout: QuoteLayout, component: PaQuotes, exact: true },
  { path: "/pa/proposal/:id", layout: QuoteLayout, component: PaProposal, exact : true },
  { path: "/pa/review/:id", layout: QuoteLayout, component: PaReviews, exact : true },
  { path: "/pa/payment-success/:id", layout: DefaultLayout, component: PaymentSuccessHealth, exact: true },
  { path: "/pa/payment/:id", layout: DefaultLayout, component: PaymentFailedHealth, exact: true },
  { path: "/life-insurance", layout: PrequoteLayout, component: LifePrequotes, exact: true },
  { path: "/life-quote/:id", layout: QuoteLayout, component: LifeQuotes, exact: true },

  { path: "/marine-insurance", layout: PrequoteLayout, component: MarinePrequotes, exact: true },
  { path: "/marine-quotes/:id", layout: QuoteLayout, component: MarineQuotes, exact: true },
  { path: "/marine/proposal/:id", layout: QuoteLayout, component: MarineProposal, exact: true },
  { path: "/marine/review/:id", layout: QuoteLayout, component: MarineReview, exact: true },
  { path: "/marine/payment-failed/:id", layout: DefaultLayout, component: PaymentSuccessHealth, exact: true },
  { path: "/marine/payment-failed/:id", layout: DefaultLayout, component: PaymentFailedHealth, exact: true },

  { path: "*", layout: PrequoteLayout, component: NotFound }
];