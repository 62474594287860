import axios from "axios";
import CryptoJS from "crypto-js";
import { BASE_URL, BASE_NODE_URL, COMMON_API_BASE_URL } from "./globle_constant";

// Utility to manage cancel tokens
const cancelTokenAllSources = [];
const cancelTokenSources = [];

export const cancelBundelListRequests = () => {
  cancelTokenSources.forEach(source => source.cancel('Operation canceled by the user.'));
  cancelTokenSources.length = 0; // Clear the array after canceling
};

export const cancelAllRequests = () => {
    cancelTokenAllSources.forEach(source => source.cancel('Operation canceled by the user.'));
    cancelTokenAllSources.length = 0; // Clear the array after canceling
};

const generateSecureString = (serverTime) => {
    //Date.now()
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
  
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    let otherstr = `jprjin_${result}_rambajrangbali_${serverTime}_sqsecure`;
    const key256C  = CryptoJS.SHA256('infkrofldjfkjuioojqwertyuiiuh').toString().substring(0, 64);
    const key128C  = CryptoJS.SHA256('qwdfgnmzxcvbnmasdfghjkiqsdfhj').toString().substring(0, 32);
    const key = CryptoJS.enc.Hex.parse(key256C);
    const iv =  CryptoJS.enc.Hex.parse(key128C);

    const vEncrypted = CryptoJS.AES.encrypt(otherstr, key, { iv: iv }).toString();

    return vEncrypted;
}

export const axiosRequest = (method, path, data={}, other={}) => {

    return new Promise((resolve, reject) => {

        let token   = (other.token) ? other.token : false;
        let node    = (other.node) ? other.node : false;
        let common    = (other.common) ? other.common : false;
        let pushAxios    = (other.cancel) ? other.cancel : false;
        let serverTime    = (other.serverTime) ? other.serverTime : 0;
        
        const apiurl = (node) ? BASE_NODE_URL : ((common) ? COMMON_API_BASE_URL : BASE_URL);
        const url = `${apiurl}${path}`;

        let headers = {
            /* 'Content-Type': 'application/json' */
            'Content-Type': 'multipart/form-data'
        };

        if(token){
            let csrfTokenKey = generateSecureString(serverTime);
            if(csrfTokenKey){
                headers['Authorization'] = csrfTokenKey;
            }
        }

        if(node || common){
            headers['Content-Type'] = 'application/json';
        }

        let is_logged = localStorage.getItem("is_logged");
        let login_type = localStorage.getItem("login_type");

        if(String(is_logged) === "true"){
            let login_name = localStorage.getItem("login_name");
            let login_data = localStorage.getItem("login_data");

            if(data instanceof FormData){
                data.append("is_logged", is_logged);
                if(login_name && login_type && login_data){
                    data.append("login_name", login_name);
                    data.append("login_type", login_type);
                    data.append("login_data", login_data);
                }
            }else{
                data['is_logged'] = is_logged;
                if(login_name && login_type && login_data){
                    data['login_name'] = login_name;
                    data['login_type'] = login_type;
                    data['login_data'] = login_data;
                }

            }
        }else{
            let partner_name = localStorage.getItem("partner_name");
            let partner_code = localStorage.getItem("partner_code");
            if(data instanceof FormData){
                data.append("is_logged", false);
                if(partner_name){
                    data.append("partner_name", partner_name);
                }

                if(login_type){
                    data.append("login_type", login_type);
                }

                if(partner_code){
                    data.append("partner_code", partner_code);
                }

            }else{
                data['is_logged'] = false; 

                if(partner_name){
                    data['partner_name'] = partner_name;
                }

                if(login_type){
                    data['login_type'] = login_type;
                }

                if(partner_code){
                    data['partner_code'] = partner_code;
                }

            }
        }

        if(data instanceof FormData){
            data.append("platform_new", "web");
        }else{
            data['platform_new'] = "web";
        }

        /* if (csrfToken) {
            headers['X-CSRF-Token'] = csrfToken;
          } */

       /*  if(csrfToken && method.toUpperCase() == "POST"){
            headers['X-CSRF-Token'] = csrfToken;
        } */

        const source = axios.CancelToken.source();
        if (pushAxios) {
            cancelTokenSources.push(source);
        }

        cancelTokenAllSources.push(source);
 
        axios({
            method: method,
            url: url,
            headers: headers,
            data: data,
            cancelToken: source.token
        }).then(response => {
            resolve(response.data); // Resolve with the response data
        }).catch(error => {
            if (axios.isCancel(error)) {
                //console.log('Request canceled', error.message);
                reject(error);
            }else{
                reject(error); // Reject with the error
            }
        });
    });
};


export const axiosFullUrlRequest = (method, url, data={},other={}) => {
    return new Promise((resolve, reject) => {

        let json    = (other.json) ? other.json : false;

        let headers = {
            /* 'Content-Type': 'application/json' */
            'Content-Type': 'multipart/form-data' 
        };

        if(json){
            headers['Content-Type'] = 'application/json';
        }


        axios({
            method: method,
            url: url,
            headers: headers,
            data: data
        }).then(response => {
            resolve(response.data); // Resolve with the response data
        }).catch(error => {
            reject(error); // Reject with the error
        });
    });
};


export const axiosNodeRequest = (method, url, token=false) => {
    return new Promise((resolve, reject) => {

        const headers = {
            /* 'Content-Type': 'application/json' */
            'Content-Type': 'multipart/form-data'
        };

        if(token){
            headers['Authorization'] = 'Bearer your_token';
        }

        axios({
            method: method,
            url: url,
            headers: headers
        }).then(response => {
            resolve(response.data); // Resolve with the response data
        }).catch(error => {
            reject(error); // Reject with the error
        });
    });
};
